<template>
  <g :fill="color" fill-rule="nonzero">
    <path
      d="M9.284.707L.707 9.293a1 1 0 0 0 0 1.414l8.577 8.585a1 1 0 0 0 1.414.001l.593-.59a1 1 0 0 0 0-1.416L3.99 10l7.3-7.287a1 1 0 0 0 0-1.415l-.593-.591a1 1 0 0 0-1.414 0z"
    />
  </g>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
